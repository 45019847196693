<template>
  <v-container fluid>
    <div class="px-2 mt-2">
      <v-row justify="space-between">
        <v-col cols="4" md="4">
          <p class="text-body-1 font-weight-regular pt-2">
            Compromiso de pago para Evaluación #{{ numeroEvaluacion }}
          </p>

          <p class="text-body-2 text--secondary font-weight-regular mt-n3">
            <v-icon class="" color="gray">mdi-calendar-range</v-icon>
            Fecha evaluación: {{ fechaEvaluacion }}
          </p>
        </v-col>

        <v-col cols="5" md="5">
          <div class="text-right">
            <v-btn
              color="#031D6A"
              class="px-3 white--text mr-4"
              @click="fnAprobarCompromiso()"
            >
              APROBAR COMPROMISO
              <v-icon class="ml-2">mdi-check</v-icon>
            </v-btn>

            <v-btn
              color="red"
              text
              class="px-3 ml-4"
              @click="fnDenegarCompromiso()"
            >
              DENEGAR COMPROMISO
              <v-icon class="ml-2 mt-n1">mdi-cancel</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- PARTE PRINCIPAL -->
    <v-row class="px-2 mt-n6">
      <!-- columna izquierda con la informacíon de la persona -->
      <v-col cols="4" md="4" xl="3">
        <v-card tile>
          <div class="text-center">
            <v-avatar size="58" color="#031D6A" class="my-3">
              <v-icon dark size="48"> mdi-account </v-icon>
            </v-avatar>
            <p class="text-h6 text--primary font-weight-regular mt-n2">
              {{ detalleEvaluacion.persona }}
            </p>
          </div>
          <div class="px-4 mt-n4 pb-2">
            <table>
              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Número de documento identifiación
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.numeroId }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Identificación extendida en
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.identificacionExtendidaEn }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Primer nombre
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.primerNombre }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Segundo nombre
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  <!-- mostramos un guión mayor si no contiene el campo, si es una cadena vacía  -->
                  <span v-if="detalleEvaluacion.segundoNombre">{{
                    detalleEvaluacion.segundoNombre
                  }}</span>
                  <span v-if="!detalleEvaluacion.segundoNombre">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tercer nombre
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  <span v-if="detalleEvaluacion.tercerNombre">{{
                    detalleEvaluacion.tercerNombre
                  }}</span>
                  <span v-if="!detalleEvaluacion.tercerNombre">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Primer apellido
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.primerApellido }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Segundo apellido
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.segundoApellido }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tercer apellido
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  <span v-if="detalleEvaluacion.tercerApellido">{{
                    detalleEvaluacion.tercerApellido
                  }}</span>
                  <span v-if="!detalleEvaluacion.tercerApellido">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Fecha nacimiento
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.fechaNacimiento }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Dirección de la consejera
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.direccionConsejera }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Dirección entrega
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.direccionEntrega }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Tipo de vivienda
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.tipoVivienda }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Ubicación
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  <span v-if="detalleEvaluacion.ubicacion">{{
                    detalleEvaluacion.ubicacion
                  }}</span>
                  <span v-if="!detalleEvaluacion.ubicacion">-</span>
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Número de celular
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.numeroCelular }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Teléfono de casa
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.telefonoCasa }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Dirección de trabajo
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.direccionTrabajo }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Teléfono de trabajo
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.telefonoTrabajo }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  NIT
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.nit }}
                </td>
              </tr>

              <tr>
                <td class="text--secondary font-weight-bold text-body-2">
                  Contado
                </td>
                <td
                  class="text--secondary font-weight-regular text-body-2"
                  align="right"
                >
                  {{ detalleEvaluacion.contado }}
                </td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>

      <!-- columna derecha -->
      <v-col cols="8" md="8" xl="9">
        <v-card tile min-height="570">
          <v-tabs color="#031D6A" left>
            <v-tab>COMPROMISO DE PAGO</v-tab>

            <!-- maquetado de CONTRATO -->
            <v-tab-item>
              <vistaCompromisoDePago />
            </v-tab-item>
          </v-tabs>

          <!-- apartado para la sección de comentarios -->
          <v-container>
            <div class="mt-n5">
              <v-row>
                <v-col cols="6">
                  <v-textarea
                    ref="comentarioUno"
                    v-model="comentarioUno"
                    label="Agregar comentario"
                    auto-grow
                    outlined
                    rows="4"
                    row-height="25"
                  ></v-textarea>

                  <v-btn
                    small
                    color="blue"
                    outlined
                    class="px-4 mt-n8"
                    elevation="2"
                    @click="fnAgregarComentarioUno()"
                  >
                    AGREGAR COMENTARIO
                  </v-btn>

                  <!-- Card para mostrar el/los comentarios -->
                  <v-card
                    class="mb-2"
                    outlined
                    min-height="130"
                    v-for="(item, j) in infoComentariosUno"
                    :key="j"
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="1">
                          <v-icon class="mt-3">mdi-comment</v-icon>
                        </v-col>
                        <v-col cols="11">
                          <p
                            class="text-body-2 font-weight-medium text--secondary"
                          >
                            Comentario por: {{ item.user }}
                          </p>
                          <p
                            class="text-body-2 font-weight-medium text--secondary mt-n4"
                          >
                            Fecha: {{ item.fecha }}
                          </p>
                        </v-col>
                      </v-row>

                      <p
                        class="text-body-2 font-weight-regular text--secondary mt-n5"
                      >
                        {{ item.msg }}
                      </p>
                    </v-container>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-textarea
                    ref="comentarioDos"
                    v-model="comentarioDos"
                    label="Agregar comentario"
                    auto-grow
                    outlined
                    rows="4"
                    row-height="25"
                  ></v-textarea>

                  <v-btn
                    small
                    color="blue"
                    outlined
                    class="px-4 mt-n8"
                    elevation="2"
                    @click="fnAgregarComentarioDos()"
                  >
                    AGREGAR COMENTARIO
                  </v-btn>

                  <!-- Card para mostrar el/los comentarios -->
                  <v-card
                    class="mb-2"
                    outlined
                    min-height="130"
                    v-for="(item, j) in infoComentariosDos"
                    :key="j"
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="1">
                          <v-icon class="mt-3">mdi-comment</v-icon>
                        </v-col>
                        <v-col cols="11">
                          <p
                            class="text-body-2 font-weight-medium text--secondary"
                          >
                            Comentario por: {{ item.user }}
                          </p>
                          <p
                            class="text-body-2 font-weight-medium text--secondary mt-n4"
                          >
                            Fecha: {{ item.fecha }}
                          </p>
                        </v-col>
                      </v-row>

                      <p
                        class="text-body-2 font-weight-regular text--secondary mt-n5"
                      >
                        {{ item.msg }}
                      </p>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vistaCompromisoDePago from './vistaCompromisoPago.vue';
export default {
  name: 'aprobarRechazarCompromisoDePago',
  components: {
    vistaCompromisoDePago,
  },
  data() {
    return {
      //variables para guardar la info que se escribe en los comentario
      comentarioUno: null,
      comentarioDos: null,

      numeroEvaluacion: 33256, //variable para mostrar el número de evaluacion en la parte superior izquierda de la pantalla
      fechaEvaluacion: '15 oct. 10:47 am', // fecha de prueba que se muestra en la card superior

      //datos (temporales) que se mostrarán en la columna de información personal
      detalleEvaluacion: {
        evaluacion: 33256,
        fechaEvaluacion: '15 oct. 10:47 am',
        fechaCompletacion: '25 oct. 10:47 am',
        fechaInicioRevision: '26 oct. 8:15 am',
        persona: 'Luisa López Pérez',
        numeroId: '231620690304',
        identificacionExtendidaEn: 'San Lucas Sacatepequez',
        primerNombre: 'Luisa',
        segundoNombre: '',
        tercerNombre: '',
        primerApellido: 'López',
        segundoApellido: 'Pérez',
        tercerApellido: '',
        fechaNacimiento: '18/04/1989',
        direccionConsejera: '7a. Av. 3-52 Zona 1',
        direccionEntrega: '7a. Av. 3-52 Zona 1',
        tipoVivienda: 'Propia',
        ubicacion: '',
        numeroCelular: '+50263985214',
        telefonoCasa: '+50278396352',
        direccionTrabajo: '12 calle Zona 8 Guatemala',
        telefonoTrabajo: '+50223961214',
        nit: '995114-8',
        contado: 'Si',
        sector: '95',
        ruta: 'EMP095',
        fechaCampania: '15/10/2023',
        codigoReferencia: 33321241,
        perfil: 'Deudor',
        fiador: '',
        resultado: 'VERDE',
        agente: 'sector95',
      },

      //info temporal que se mostrará en los comentarios
      infoComentariosUno: [
        {
          user: 'jcarlos',
          fecha: '31 oct. 2023 8:15 am',
          msg: 'La fotografía de la tercera parte del contraro no es legible',
        },
      ],

      infoComentariosDos: [
        {
          user: 'jcarlos',
          fecha: '31 oct. 2023 8:15 am',
          msg: 'Se solicito a la agente de comercio que se rectificara la tercer parte del contrato',
        },
      ],
    };
  },

  methods: {
    //funcion para cuando se da clic en el botón de CONTINUAR GESTIÓN
    fnAprobarCompromiso() {
      console.log('se aprobó el compromiso de pago');

      //------ solo para pruebas****
      localStorage.setItem('aprobacion', true); //guardamos la variable para verificar si finalizó la revisión (dicha variable local se utiliza en la ventana gestiones con rectificaciones)

      this.$router.push('/gestiones-con-compromiso-de-pago'); //regresamos a gestiones con rectificaciones
    },

    //funcion para cuando se da clic en botón de CANCELAR GESTIÓN
    fnDenegarCompromiso() {
      console.log('se denegó el compromiso de pago');
    },

    //clic en los botones de AGREGAR COMENTARIO
    fnAgregarComentarioUno() {
      console.log('comentario uno: ', this.comentarioUno);
      this.$refs.comentarioUno.reset(); //reiniciamos el text-fiel de comentario
    },
    fnAgregarComentarioDos() {
      console.log('comentario dos: ', this.comentarioDos);
      this.$refs.comentarioDos.reset(); //reiniciamos el text-fiel de comentario
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
}
</style>
