<template>
  <v-container>
    <v-row>
      <v-col v-for="(item, i) in itemsDocumento" :key="i" cols="3">
        <v-card outlined min-height="190">
          <div class="text-center">
            <v-icon size="52" class="mt-2"> mdi-file-document-outline</v-icon>
            <p class="text--primary font-weight-regular text-h6">
              Compromiso de <br />
              pago
            </p>
          </div>
          <v-card-actions class="justify-center pb-6 mt-n2">
            <v-btn
              x-small
              outlined
              class="px-4 mr-n2"
              elevation="3"
              @click="fnMostrarDocumento(item)"
            >
              VER DOCUMENTO
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- ventana de dialogo para mostrar la/las fotografía -->
    <v-dialog v-model="dialogVerDocumento" width="900" persistent>
      <v-card>
        <v-card-title class="text-body-1">Compromiso de pago</v-card-title>
        <v-divider></v-divider>

        <div class="d-flex justify-center">
          <v-img
            alt="gotografía contrato"
            class="shrink"
            contain
            src="@/assets/images/contrato.jpg"
            width="300"
          />
        </div>

        <v-card-actions class="justify-end pb-6">
          <v-btn
            color="#031D6A"
            class="px-5 white--text mr-4"
            small
            @click="fnDescargarDocumento()"
          >
            DESCARGAR
            <v-icon class="ml-2">mdi-download</v-icon>
          </v-btn>
          <v-btn text class="mr-1" @click="dialogVerDocumento = false" small>
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'vistaCompromisoPago',
  data() {
    return {
      //variables para las ventanas de diálogo el/los documentos
      dialogVerDocumento: false,

      //info para mostrar en las cards de documentos, se deja de esta manera por si hay mas de un compromiso de pago
      itemsDocumento: [
        {
          linkDocumento: 'compromiso de pago',
        },
      ],

      infoCompromisoPago: {}, //para almacenar la info cuando damos clic en algún botón de FOTOGRAFÍA
    };
  },

  methods: {
    // función para mostrar la fotografía dependiendo del botón presionado
    fnMostrarDocumento(item) {
      console.log('documento: ', item.linkDocumento);
      this.infoCompromisoPago = Object.assign(item);
      this.dialogVerDocumento = true;
    },

    //cuando se da clic en el botón de DESCARGAR que aparece en la respectiva ventana de dialogo que muestra las distintas fotografías
    fnDescargarDocumento() {
      //comprobamos
      console.log(
        'descargando documento...',
        this.infoCompromisoPago.linkDocumento
      );
      this.dialogVerDocumento = false; //cerramos la ventana que muestra el documento por que ya se descargó
    },
  },
};
</script>

<style></style>
